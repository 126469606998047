<template>
  <n-layout>
    <n-layout-header class="header">深圳市芙玥嘉科技有限公司</n-layout-header>
    <n-layout-content class="content" content-style="padding: 24px;">
      <n-flex vertical>
        <div class="title">让我们一起做些时髦又酷的东西吧！</div>
        <div class="sub-title">Let's make something trend and cool!</div>
        <n-flex class="ability-flex" justify="space-around" :size="32" :wrap="false">
          <AbilityItem image="home_design.webp" title="精美设计" text="Android、iOS、Web"/>
          <AbilityItem image="home_developer.webp" title="高效开发" text="移动应用、后端开发速度与品质"/>
          <AbilityItem image="home_data.webp" title="发布运营" text="应用数据统计、用户服务、快速分析"/>
        </n-flex>
        <div class="title">我们一直在做的</div>
        <div class="sub-title">移动端：Android、iOS、微信小程序<br/>
          Web端：Vue、Flutter 框架的网页应用<br/>
          后端：Golang、Kotlin、Rust 开发的后台系统<br/>
          从应用开发直至部署、运营数据分析，为用户提供便捷高效的一站式服务。<br/>
        </div>
        <n-flex class="ability-flex" justify="space-around" :size="32" :wrap="false">
          <AbilityItem image="home_mobile.webp" title="移动应用开发" text="Android、iOS、微信小程序"/>
          <AbilityItem image="home_web.webp" title="网页应用开发" text="Vue、Flutter 框架开发"/>
          <AbilityItem image="home_backend.webp" title="服务的开发" text="Golang、Kotlin、Rutt、Python 语言开发"/>
        </n-flex>
        <div class="title">我们的产品</div>
        <AppShowItem icon="ps/icon.webp" name="PS图片魔法师"
                     text="一款好用智能的图片处理修图手机软件，超多强大的功能，一键处理照片美化"
                     download-url="https://www.pgyer.com/tLwO15fr"
                     :store-images="psStoreImages"/>
      </n-flex>
    </n-layout-content>
    <n-layout-footer class="footer">
      <n-flex vertical align="center">
        <div @click="toBeian">粤ICP备2024341562号</div>
        <!--        <n-flex align="center" @click="toPolice">-->
        <!--          <n-image width="16" height="16" src="home_police.webp"/>-->
        <!--          <div>赣公安网备36090202000137号</div>-->
        <!--        </n-flex>-->
      </n-flex>
    </n-layout-footer>
  </n-layout>
</template>
<script>

import {NFlex, NLayout, NLayoutContent, NLayoutFooter, NLayoutHeader} from "naive-ui";
import AbilityItem from "@/components/AbilityItem.vue";
import AppShowItem from "@/components/AppShowItem.vue";

const psStoreImages = ['ps/1.jpg', 'ps/2.jpg', 'ps/3.jpg', 'ps/4.jpg', 'ps/5.jpg'];
const aimageStoreImages = ['aimage/1.webp', 'aimage/2.webp', 'aimage/3.webp', 'aimage/4.webp', 'aimage/5.webp'];
const lrStoreImages = ['lr/1.jpg', 'lr/2.jpg', 'lr/3.jpg', 'lr/4.jpg', 'lr/5.jpg'];

export default {
  name: 'HomeView',
  components: {AppShowItem, NLayoutFooter, AbilityItem, NFlex, NLayoutContent, NLayoutHeader, NLayout},
  props: {},
  methods: {toBeian, toPolice},
  setup() {
    return {psStoreImages, aimageStoreImages, lrStoreImages}
  }
}

function toBeian() {
  window.open("https://beian.miit.gov.cn/", '_blank')
}

function toPolice() {
  window.open("https://beian.mps.gov.cn/#/query/webSearch", '_blank')
}


</script>

<style>
.header {
  background: #18181c;
  padding: 24px;
  font-size: 18px;
  font-weight: bold;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.sub-title {
  text-align: center;
}

.ability-flex {
  padding-top: 16px;
}

.footer {
  padding-top: 16px;
  padding-bottom: 16px;
}

</style>
